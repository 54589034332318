import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import _ from "lodash";
import axios from "axios";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import smoothscroll from "smoothscroll-polyfill";
import moment from "moment";
moment.locale("es");
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import SimpleImageZoomer from "vue2-simple-image-zoomer";
// you probably need to import built-in style
import "vue2-simple-image-zoomer/dist/vue2-simple-image-zoomer.css";
Vue.use(SimpleImageZoomer);

import imageZoom from "vue-image-zoomer";
Vue.component("image-zoom", imageZoom);

import VueHead from "vue-head";
Vue.use(VueHead);

// Create an instance of Notyf
const notyf = new Notyf({
  duration: 4000,
});

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.UIkit = UIkit;

// loads the Icon plugin
UIkit.use(Icons);

// kick off the polyfill!
smoothscroll.polyfill();

// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule]);
}

localize("es", es);
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import PaginationButtons from "@/views/Utils/PaginationButtons.vue";
Vue.component("pagination-buttons", PaginationButtons);
import WeightInput from "@/views/Utils/WeightInput.vue";
Vue.component("weight-input", WeightInput);
import SizeInput from "@/views/Utils/SizeInput.vue";
Vue.component("size-input", SizeInput);
import Parametrizations from "@/views/Utils/Parametrizations.vue";
Vue.component("parametrizations", Parametrizations);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 302) {
      console.log("Should redirect using vue-router");
    } else {
      return response;
    }
  },
  (error) => {
    // Display an error notification
    notyf.error(error.response.data.message);
    // Check if session has expired
    if (error.response.status === 401) {
      // UIkit.notification({
      //   message: "Tu sesión ha expirado, por favor ingresa nuevamente.",
      //   status: "warning"
      // });
      store.dispatch("authInvalidate");
      router.push("/login");
      return false;
    }

    // Check if 2FA is required
    if (
      error.response.data &&
      error.response.data.message &&
      (error.response.data.message === "2fa_token_required" ||
        error.response.data.message === "2fa_invalid_token")
    ) {
      // Should prompt for 2FA token

      // Get a copy of the original request to replay it later
      // const originalRequest = error.config;

      // let promptMessage;
      switch (error.response.data.message) {
        case "2fa_token_required":
          // promptMessage = "Ingrese su token:";
          break;
        case "2fa_invalid_token":
        // promptMessage =
        // "El token ingresado es inválido, ingrese nuevamente su token:";
      }

      // Show a modal to enter the 2FA token
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + store.getters.authToken;
  config.headers["Storefront-Id"] = process.env.VUE_APP_STOREFRONT_ID;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      config.headers["Latitude"] = latitude;
      config.headers["Longitude"] = longitude;
    });
  } else {
    console.log("Geolocation is not supported by this browser.");
  }

  document.getElementById("global-spinner").style.display = "block";

  return config;
});

axios.interceptors.response.use(
  function (response) {
    document.getElementById("global-spinner").style.display = "none";
    return response;
  },
  function (error) {
    document.getElementById("global-spinner").style.display = "none";
    return Promise.reject(error);
  }
);

axios.defaults.withCredentials = true;
let baseUrlBackend = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = baseUrlBackend + "/api";
Vue.prototype.axios = axios;
Vue.prototype._ = _;

Vue.mixin({
  data() {
    return {
      api: axios.defaults.baseURL,
      storefrontId: process.env.VUE_APP_STOREFRONT_ID,
      baseUrlBackend: baseUrlBackend,
    };
  },

  methods: {
    toCurrency: (amount) =>
      (amount < 0 ? "-" : "") +
      "$" +
      Math.abs(amount)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    formatThousands: (number) =>
      parseInt(number)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    formatDate: (date) => moment.utc(date).local().format("LL"),
    formatDatetime: (datetime) =>
      moment.utc(datetime).local().format("LL hh:mm:ss A"),
    formatShortDatetime: (datetime) =>
      moment.utc(datetime).local().format("DD/MM/YY HH:mm:ss"),
    formatDatetimeElapsed: (datetime) => moment.utc(datetime).local().fromNow(),
    shippingLabelStatus: (status) => {
      switch (status) {
        case "REQUESTED":
          return "Solicitado";
        case "ON_ROUTE":
          return "En camino";
        case "DELIVERED":
          return "Entregado";
      }
    },
  },
});

Vue.config.productionTip = false;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'fb2b562d25a515ae6e38',
  cluster: 'us2',
  encrypted: true,
  authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
});

window.pusher = new Pusher('fb2b562d25a515ae6e38', {
    cluster: 'us2',
    encrypted: true,
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
